<template>
  <div>
    <Footer></Footer>
    <div class="content" v-if="ViewType === 'seven'">
      <span class="title">
        第七届“大唐杯”比赛精彩回顾&闭幕式暨颁奖典礼成功举办
        <!--<p class="titext">
              <span>“大唐杯”组委会</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;
              <span>发布于2022-07-19</span>
            </p>-->
      </span>
      <div class="contbox">
        <!-- <p class="text"> 2022年7月17日，第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛在武汉职业技术学院落下帷幕。</p> -->
        <div class="item_img">
          <img src="@/assets/images/s1.jpg" />
        </div>
        <p class="text_t">
          2020年10月23至25日，第七届“大唐杯”总决赛在北方工业大学图书馆报告厅落下帷幕。北方工业大学副校长王建稳、中信科移动副总经理蔡鑫、工信部人才中心副主任李宁、北京市教委高教处处长刘霄、中国通信协会副秘书长李北林、北京邮电大学教务处长王卫东、中信科移动5G产品线副总裁李文、中信科移动客服中心总经理蒋珀及北京高校电子信息类专业群相关院校领导出席颁奖典礼。
        </p>
        <div class="item_img">
          <img src="@/assets/images/s2.jpg" />
        </div>
        <p class="text_t">
          受新冠疫情影响，本届大赛采用线上与线下结合的方式进行，历时6个月。竞赛内容设置上充分吸收了大唐移动在5G标准创新、设备研发、5G网络部署及5G系统运维等不同专业领域的高级工程师的技术经验。本届大赛作为全国首个以5G系统设备实践为主、5G技术原理和系统仿真相结合的大型竞技比赛。吸引了全国300多所院校、8000余人踊跃参赛。公司以大赛为抓手，同时开发出符合教学要求的工程实践平台及匹配仿真实践的系列课程。真正达到了以赛促学、以赛促教、以赛促改、以赛促建的办赛预期，促进了ICT
          相关专业教学内容和教学方法的改革创新，提升了高校师生对复杂工程问题的分析和解决能力。
        </p>
        <div class="item_img">
          <img src="@/assets/images/s3.jpg" />
        </div>
        <p class="text_t">
          与会领导对本届大赛内容设置、组织策划及所产生的社会贡献给予了充分肯定。中信科移动副总经理蔡鑫在致辞中表示，新一代信息技术快速发展，以数字化、网络化、智能化为特征的智慧社会正加速到来。国家新基建战略也加快了5G网络部署力度。人才是5G应用推进的重要支撑，人才发展是通信行业发展的重要因素。在5G技术促进物联网、车联网、人工智能、工业智能等全行业发展的同时，将在2020年至2025年直接带动310万个就业岗位，实现社会千行百业协同发展。大唐移动作为移动通信网络设备和网络优化服务核心提供商，不仅具备4G/5G基站、天线和网络优化全系列产品，而且致力于营造产业生态，公司与行业伙伴共同落地了多个5G标杆教学基地及智能网联汽车。中信科移动作为5G系统设备开发的大型央企，将继续坚持自主化研究，以加速5G建设，服务5G新场景，赋能5G新应用为契机，不断深化同全国高校在5G通信技术实践实训平台建设、双师培养、高质量课程建设、5G应用创新等方面开展合作，助力学校新工科建设。提供优质人才培养和教学质量提升综合支撑。
        </p>
        <div class="item_img">
          <img src="@/assets/images/s4.jpg" />
        </div>
        <p class="text_t">
          大赛期间，参赛院校的教学专家们齐聚一堂，围绕“产教融合聚焦5G人才培养、双元育人应对5G时代挑战”，从5G工程实践教学、课程体系改革、校企合作服务产学研课题、应用型人才培养等方面展开了深入研讨，形成了多项交流合作意向和共识，对大赛的方向拓展提供了重要参考。
        </p>
        <div class="img_box"></div>
      </div>
    </div>
    <div class="content" v-if="ViewType === 'eight'">
      <span class="title">
        第八届“大唐杯”比赛在京圆满谢幕
        <!--<p class="titext">
              <span>“大唐杯”组委会</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;
              <span>发布于2022-07-19</span>
            </p>-->
      </span>
      <div class="contbox">
        <!-- <p class="text"> 2021年6月28日，第八届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛在北方工业大学体育馆落下帷幕。中信科移动通信技术股份有限公司副总经理马军、工信部人才交流中心副主任李宁、中国通信协会副秘书长李北林、北方工业大学副校长栗苹、工业和信息化职业教育教学指导委员会委员孙卫平、北京邮电大学教务处长王卫东、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀及北京市高校电子信息类专业群相关院校领导出席颁奖典礼。</p> -->
        <p class="text_t">
          2021年6月28日，第八届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛在北方工业大学体育馆落下帷幕。中信科移动通信技术股份有限公司副总经理马军、工信部人才交流中心副主任李宁、中国通信协会副秘书长李北林、北方工业大学副校长栗苹、工业和信息化职业教育教学指导委员会委员孙卫平、北京邮电大学教务处长王卫东、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀及北京市高校电子信息类专业群相关院校领导出席颁奖典礼。
        </p>
        <div class="item_img">
          <img src="@/assets/images/e1.jpg" />
        </div>
        <p class="text_t">
          与会嘉宾对大赛内容设置、组织策划、产教融合导向及所产生的社会贡献给予了充分肯定。尤其在庆祝建党百年的伟大历史时刻，对作为移动通信行业中最具影响力的大学生竞赛“大唐杯”全国大学生移动通信5G技术大赛成功进入2020全国普通高校大学生竞赛排行榜表达了祝贺和高度认可。
        </p>
        <p class="text_t">
          不忘初心、砥砺前行，本届大赛在践行教育部、工信部倡导的“政、产、学、研、用”思路，推动专业教学与产业工程深度融合，落实落地新工科新举措，提升教学水平和人才培养质量等方面的影响力持续提升。此次大赛覆盖全国30个省市自治区,参与大赛的院校数量达到300余，其中不乏哈尔滨工业大学、武汉大学、电子科技大学、兰州大学、国防科技大学等多所985和211建设院校。多所教育部获批的双高建设职业院校均踊跃参赛。参赛师生共计1.5万余名，创下历届大赛参赛人数新高。1700余名选手经过省赛激烈的选拔后脱颖而出，以线上或线下的方式参加全国总决赛的桂冠争夺。本届大赛分为本科组、高职组、研究生邀请赛、教师邀请赛、一带一路留学生邀请赛五个类别，以真设备、真场景、真问题作为竞赛核心，选手们分别在5G技术原理、5G工程实践及5G+创新应用三方面进行了真实设备实践、真实场景应用、真实问题解决等工程能力的比拼。
        </p>
        <div class="item_img">
          <img src="@/assets/images/e2.jpg" />
        </div>
        <p class="text_t">
          中信科移动副总经理马军在颁奖典礼致词时强调：大唐杯坚持立德树人的出发点和落脚点，充分发挥竞赛育人功能，深化竞赛与教学、竞赛与育人的内在联系，真正实现以赛促学、以赛促教。大赛以推进新工科建设，推动专业教学与工程实践教学深度融合，促进高校教学改革，提升教学水平和人才培养质量为根本遵循。充分吸收了中信科移动在5G标准创新、设备研发、网络部署及5G+垂直产业创新应用的产业成果。新一轮科技革命和产业变革正加速演进，并深刻改变着世界格局。能否掌握核心技术，不仅影响着全球产业布局，也决定着国家民族的前途命运。中信科移动主动顺应数字时代浪潮，以加速5G建设，服务5G新场景，赋能5G新应用为契机，不断深化同全国高校在5G通信技术实践实训平台、双师培养、高质量课程体系建设、5G+垂直产业创新应用等方面深度融合，助力高校实现人才培养和教学质量双提升，实现企业用人与高校育人平台双循环。
          信息时代人才战略是5G应用推进的重要支撑，更是在充满挑战的时代加速数字化转型的重要引擎，人才发展是通信行业发展的重要因素。作为信息通信领域中央企业的领军代表之一，中信科移动将继续参与大赛的所有高校和兄弟单位共同携手，以大赛为纽带，构建移动通信产业与人才培养服务生态圈，做新时代数字化产业人才培养的技术支撑，实现与高校培养体系的融合，培养符合产业标准的通信人才，助力国家信息产业的发展。
        </p>
        <div class="item_img">
          <img src="@/assets/images/e3.jpg" />
        </div>
        <p class="text_t">
          北方工业大学副校长栗苹、工业和信息化部人才交流中心副主任李宁、中国通信协会副秘书长李北林、工信部行指委委员孙卫平、北京邮电大学教务处处长王卫东先后致词。大家高度认可大唐杯在推动通信人才培养、促进专业建设高质量发展的突出作用，并表示今后将继续通力合作，从政策、行业和育人等方面进一步创新移动通信人才培养的理念和模式，打造行业竞赛的金字招牌。
          大赛期间，参赛院校的教学专家们齐聚一堂，围绕“以赛促学、以赛促教、共迎5G数字化转型时代新挑战”，从5G工程实践教学、课程体系改革、校企合作服务产学研课题、应用型人才培养等方面开展了电子信息类专业建设高峰论坛，形成了多项交流合作意向和共识，对办赛质量提升和教学业务拓展提供了重要参考。大赛期间各高校专家与专业教师们受邀参观了中信科移动国家重点实验室，并对大赛的未来发展方向与竞赛模式提供了新的思路与建议。
        </p>
        <div class="img_box"></div>
      </div>
    </div>

    <div class="content" v-if="ViewType === 'eleven'">
      <p class="title1">揭榜5G+创新应用难题</p>
      <p class="title2">助力中信科移动赋能垂直行业数智化转型</p>
      <p class="title3">中信科移动通信技术股份有限公司（2023年10月14日）</p>
      <div class="contbox">
        <!-- <p class="text"> 2021年6月28日，第八届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛在北方工业大学体育馆落下帷幕。中信科移动通信技术股份有限公司副总经理马军、工信部人才交流中心副主任李宁、中国通信协会副秘书长李北林、北方工业大学副校长栗苹、工业和信息化职业教育教学指导委员会委员孙卫平、北京邮电大学教务处长王卫东、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀及北京市高校电子信息类专业群相关院校领导出席颁奖典礼。</p> -->
        <p class="text_t">
          习近平总书记多次强调：“可以探索搞揭榜挂帅，把需要的关键核心技术项目张出榜来，英雄不论出处，谁有本事谁就揭榜。”为贯彻落实总书记的重要指示，引导和促进大学生踊跃投身5G技术创新应用赋能垂直行业数字化转型发展第一线，加速大学生科技创新成果向现实生产力转化，中信科移动通信技术股份有限公司提名的5G+创新应用仿真设计赛成功入选由共青团中央等举办的第十八届“挑战杯”全国大学生课外学术科技作品竞赛（以下简称“挑战杯”竞赛）“揭榜挂帅”专项赛。并于近日在贵州大学成功举办了该专项赛的擂台赛争夺。
        </p>
        <!-- <div class="item_img">
          <img src="@/assets/images/e1.jpg" />
        </div> -->
        <p class="heightBright">发榜论英雄，亮剑5G创新应用设计赛</p>
        <p class="text_t">
          2018年习近平总书记第二次到中国信息通信科技集团公司视察时语重心长的说：核心技术、关键技术、国之重器必须立足于自己，科技攻关约好摒弃幻想！你们所从事的光通信事业很重要，是国家需要，希望你们加快脚步，更快占领一些制高点。我相信，你们这个企业会越办越好，祝你们蒸蒸日上！今年集团坚持发展创新为第一要务，人才为第一资源，取得了令人瞩目的科技创新、产业进步成果。
        </p>
        <p class="text_t">
          中信科移动以习近平总书记视察集团时重要讲话为发展契机，践行创新型国家战略，发挥央企带动力，努力打造信息通信网络建设的国家队、主力军。公司在人才战略发展方面，培养了数十万信息通信领域的高质量创新拔尖人才。今年，更是积极参与到“挑战杯”竞赛“揭榜挂帅”专项赛中。以5G+创新应用仿真设计为题，紧扣国家将新一代信息技术作为新基建之首的战略方向，具有极为重要的意义。本赛项聚焦5G技术应用创新，深度挖掘5G技术在垂直应用领域的创新应用和关键技术突破，为千行百业实现数智化转型发展提供有力支撑，为国家产业基础高级化与产业链现代化协同发展提供有力技术支撑。
        </p>
        <p class="text_t">
          本赛项吸引了来自全国16个省份的39所高校积极参与。其中既有来自电子科技大学、北京邮电大学、西安交通大学等一大批双一流高校、也有来自华北电力大学、中国石油大学（华东）等一批行业特色高校、更有来自重庆电子工程职业学院、武汉职业技术学院、深圳职业技术大学等一批职业类高校的优秀学子们踊跃揭榜。参赛队提交的创新方案既有通信技术的创新，又有行业应用的突破；既具有工程项目逻辑性能力挑战，又具备项目方案落地推广的经济价值。作品内容涵盖了5G+人工智能、工业互联网、智能制造、智慧医疗、数字化电网、智能网联汽车、智慧城市等工业生产和生活服务领域。有效促进了5G技术的赋能国家经济社会数智化转型发展的人才储备，也将激发政府、企业、高校等各界的创新活力。为我国信息通信的发展奠定坚实基础，为中信科移动抢占5G/6G技术战略高地奠定了产业基础和人才发展战略基础，为信息技术赋能垂直行业应用树立了典范。
        </p>
        <div class="item_img">
          <img src="../Home/Home_img/leftReview.png" />
        </div>
        <p class="heightBright">竞榜创未来，赋能千行百业数智化转型</p>
        <p class="text_t">
          数字经济事关国家发展大局。近年来，以5G、互联网、大数据、人工智能、区块链等新一代信息技术的加速创新，日益融入社会发展各领域全过程。新技术催生的数字经济发展速度之快、辐射范围之广、影响程度之深前所未有，正在成为重组全球要素资源、重塑全球经济结构、改变全球竞争格局的关键力量。面向未来，充分发挥5G技术优势，促进数字技术和实体经济深度融合，赋能传统产业转型升级，催生新产业新业态新模式，不断做强做优做大我国数字经济。在此背景下，参赛高校充分认识5G技术特征，重点突出5G技术与工业经济之间深度双向赋能的关系和影响，着力推动5G技术创新应用千行百业提升工业生产效能和现代服务产业质量水平，积极挖掘参赛作品特色和亮点。
        </p>
        <p class="text_t">
          电子科技大学参赛团队创新性提出“安行5G—基于5G的多源信息融合判决行车保障系统”方案。充分彰显了学生们在未来智能网联汽车领域关键技术竞争中所展现的价值和创新力。方案突破性的将5G技术的高带宽、低时延特性应用于道路安全行车保障系统，通过终端、边缘计算单元和云端的结构设计实现互联互通，构建了一个高效的信息处理体系，实现了“终端收集多源信息，边缘计算单元进行高强度计算，云端进行融合判决”的智能判决机制。从根本上解决了传统驾驶员监测系统存在的缺陷，为道路安全提供了更加可靠的保障。同时，也与5G时代万物互联的趋势紧密契合，充分发挥了5G技术的优势，将终端、边缘计算和云端相互融合，提升了系统的整体效能。
        </p>
        <p class="text_t">
          本赛项中类似的创新方案不仅为行业发展注入了新的活力，也为科技强国建设贡献了一份厚实的力量。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven1.png" />
        </div>
        <p class="heightBright">夺榜育人才，重塑大国工匠培养新格局</p>
        <p class="text_t">
          5G+创新应用仿真设计赛项以“你来挑，我来战”为主题，崇尚“英雄不论出处，谁有本事谁揭榜”，秉承以国家在信息通信领域的重大需求为导向、以竞争协同机制为手段、以解决5G技术赋能垂直行业高质量发展中的实际问题为目标的思路，聚焦“卡脖子”技术，着力搭建培养磨砺大学生科技自立自强精神的擂台，切实引领青年学子胸怀“国之大者”，集智助力破解5G关键技术难题和行业数字化转型发展的现实问题。大赛以中信科移动真硬件、真场景、真问题、真应用作为比拼核心，参赛队在5G技术原理、5G工程实践及5G+创新应用三方面进行真工程实践、真场景应用、真问题解决、真行业赋能。
        </p>
        <p class="text_t">
          通过本赛项，充分发挥了中信科移动积极顺应数字化转型时代浪潮的发展优势，以“创新5G标准，加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同全国高校在实训平台、双师培养、通信产品设计开发、工程项目管理、5G+垂直产业创新应用、新工科数字化人才培养等方面深度融合，助力高校实现卓越人才培养和专业建设双提升，实现“企业用人”与“高校育人”双循环。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven2.png" />
        </div>
        <p class="text_t">
          华北电力大学学子们紧扣电力与通信领域未来发展，成功实现智慧电力网络设计与5G技术深度融合，为我国数字化智慧电网建设奠定坚实基础。他们注重将“挑战杯”的创新过程将专业课程的知识学习与工程实践的创新相结合，以“挑战杯”竞赛方案设计目标为结果，灵活运用工程项目化管理和方案经济决策分析方法，进行项目化竞赛过程执行。为新工科人才工程思维逻辑培养和解决复杂工程问题能力培养的模式创新提供了重要参考。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven3.png" />
        </div>
        <p class="text_t">
          华中科技大学参赛团队完成的智慧隧道平台竞赛项目在5G+智慧交通领域具有里程碑式意义。竞赛设计中引入节点可靠部署方案，解决了大规模节点部署难题。通过自动化寻找最佳节点部署方案，显著提升了部署效率，保证了节点可靠性、稳定性和经济性。为中信科移动在5G赋能智慧交通应用方面提供了有效解决方案，并将进一步引入到企业商用方案的推广中，成为“挑战杯”专项赛中实现校企深度融合的典型案例。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven4.png" />
        </div>
        <div class="item_img">
          <img src="@/assets/images/eleven5.png" />
        </div>
        <p class="text_t">
          中国石油大学参赛团队利用5G技术赋能油气产业科研创新，整合共享资源，运用数字工具提高研发效率。成功打造智能海上油气田，实现全过程实时监控、智能诊断、自动处理、优化管理。未来，将实现5G技术助力石油行业创新发展，为能源可持续发展和安全保障贡献中国力量。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven6.png" />
        </div>
        <div class="item_img">
          <img src="@/assets/images/eleven7.png" />
        </div>
        <p class="text_t">
          东南大学参赛队将项目创新设计聚焦于5G毫米波通信系统在无人机通信领域的关键技术突破。提出了低复杂度的无人机调度和高能效的波束管理技术创新方案，成功解决了波束间干扰和多天线覆盖问题。此外，针对大规模MIMO信号检测，提出了高效的技术方案，显著提升了系统运算效率。创新成果直接赋能到智慧城市三维建模中。成为以应用需求和场景应用倒逼技术创新突破的典范。
        </p>
        <div class="item_img">
          <img src="@/assets/images/eleven8.png" />
        </div>
        <p class="heightBright">结语</p>
        <p class="text_t">
          中信科移动作为移动通信领域领军企业，以第十八届“挑战杯”竞赛中5G+创新应用仿真设计专项赛为契机，围绕客户体验，进一步携手广大高校，拓展国内外信息通信产业格局；坚持以自主创新驱动价值创造，持续打造移动通信领域的核心技术、国之重器、创新高地，为全球电信运营商和行业客户提供领先的移动通信技术、信息通信产品与综合服务解决方案。用央企的实际行动号召广大青年学子听党话、跟党走，躬身践行“科创先行 强国有我”的青春誓言，奏响新时代青年学子“请党放心、强国有我”的奋斗强音，为建设社会主义现代化强国汇聚磅礴的青春动能，在创新实践中发现人才、培育人才、凝聚人才。
        </p>
        <div class="img_box"></div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
  
  <script>
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import NinthNPage from "./NinthNPage.vue";
import NinthBPage from "./NinthBPage.vue";
import NinthWPage from "./NinthWPage.vue";
import playVPage from "./playVPage.vue";
import $ from "jquery";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "ViewDetail",
  components: {
    Footer,
    Bottom,
    NinthNPage,
    NinthBPage,
    NinthWPage,
    playVPage,
  },
  data() {
    return {
      ViewType: "",
    };
  },
  methods: {},
  mounted() {},
  created() {
    this.ViewType = this.$route.query.vType;
  },
};
</script>
  
  <style scoped lang="scss">
@import "./Brief.scss";
</style>
  